@tailwind base;

button:focus {
	@apply outline-none;
}

*:focus {
	@apply ring-2 ring-offset-2 ring-green-400 outline-none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}

.availability .bold {
	@apply font-bold;
}

.availability .link {
	@apply font-bold inline-block text-green-400 underline;
}

@tailwind components;

@tailwind utilities;
