.is-draggable, .flickity-viewport, .flickity-slider {
	@apply ring-0;
}

.flickity-button {
	@apply text-white bg-gray-800 bg-opacity-25;
}

.flickity-button:disabled {
	@apply opacity-100 bg-gray-800 bg-opacity-10;
}

.flickity-prev-next-button {
	@apply h-8 w-8;
}

.flickity-prev-next-button:hover {
	@apply bg-gray-800 bg-opacity-100;
}

.flickity-prev-next-button.previous {
	@apply -left-6;
}

.flickity-prev-next-button.next {
	@apply -right-6;
}

.flickity-prev-next-button .flickity-button-icon {
	@apply top-0 left-0 h-full w-full;
}
